import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import notification from 'ant-design-vue/es/notification'
import { VueAxios } from './axios'
import { ACCESS_TOKEN } from '@/store/mutation-types'

// 创建 axios 实例
const service = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL, // api base_url
  timeout: 6000 // 请求超时时间
})

const err = (error) => {
  if (error.response) {
    const data = error.response.data
    const token = Vue.ls.get(ACCESS_TOKEN)
    if (error.response.status === 403) {
      notification.error({
        message: 'Forbidden',
        description: data.message
      })
    }
    if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
      notification.error({
        message: 'Unauthorized',
        description: 'Authorization verification failed'
      })
      if (token) {
        store.dispatch('Logout').then(() => {
          setTimeout(() => {
            window.location.reload()
          }, 1500)
        })
      }
    }
  }
  return Promise.reject(error)
}

// 客户端请求统一处理
service.interceptors.request.use(config => {
  const token = Vue.ls.get(ACCESS_TOKEN)
  if (token) {
    config.headers['authorization'] = token // 让每个请求携带自定义 token 请根据实际情况自行修改
  }
  return config
}, err)

// 服务端返回拦截处理
service.interceptors.response.use((response) => {
  // 接口返回 HTTP CODE 都为200，以status=error/success，error_code=0/400/401/403 进行区分
  // eslint-disable-next-line camelcase
  const errorCode = response.data.error_code
  const token = Vue.ls.get(ACCESS_TOKEN)
  // eslint-disable-next-line camelcase
  if (errorCode === 401) {
    // 需要登录
    notification.error({
      message: 'Unauthorized',
      description: 'Authorization verification failed'
    })

    if (token) {
      store.dispatch('Logout').then(() => {
        setTimeout(() => {
          window.location.reload()
        }, 1500)
      })
    }
  } else if (errorCode === 403) {
    // 权限不足
    notification.error({
      message: 'Forbidden',
      description: response.data.msg
    })
    // throw SyntaxError()
  }

  // 如果header中携带了Authorization，保存起来，有效期为2小时
  if (response.headers['authorization']) {
    Vue.ls.set(ACCESS_TOKEN, response.headers['authorization'], 2 * 60 * 60 * 1000)
  }

  // 如果header中携带了content-disposition，向下传递response，让程序能够获取到文件名
  if (response.headers['content-disposition']) {
    return response
  }
  return response.data
}, err)

const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, service)
  }
}

export {
  installer as VueAxios,
  service as axios
}
