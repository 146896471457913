// 定义全局函数位置
import moment from 'moment'

export default {
    install(Vue) {
        Vue.prototype.timestampToMoment = (timestamp) => {
            if (timestamp !== '') {
                if (timestamp.toString().length < 13) {
                    timestamp = timestamp * 1000
                }
                return moment(timestamp)
            } else {
                return moment()
            }
        }
    }
}
