const api = {
  Login: '/auth/login',
  Logout: '/auth/logout',
  ForgePassword: '/auth/forge-password',
  Register: '/auth/register',
  twoStepCode: '/auth/2step-code',
  SendSms: '/account/sms',
  SendSmsErr: '/account/sms_err',

  // admin
  AdminList: '/admin/admin/getlist',
  AdminInfo: '/admin/admin/getInfo',
  AdminAdd: '/admin/admin/add',
  AdminUpdate: '/admin/admin/update',
  AdminDelete: '/admin/admin/delete',

  // admin group
  AdminGroupAdd: '/admin/admin_group/add',
  AdminGroupUpdate: '/admin/admin_group/update',

  // auth node
  NodeList: '/admin/rule/getlist',
  NodeTreeList: '/admin/rule/treelist',
  NodeUpdate: '/admin/rule/update',
  NodeDelete: '/admin/rule/delete',
  NodeAdd: '/admin/rule/add',

  // email manage
  emailReplaceVars: '/admin/email/replaceVars',
  EmailType: '/admin/email/type',
  GetAddTempPage: '/admin/email/getAddTempPage',
  EmailTemplateList: '/admin/email/templates',
  EmailTemplateInfo: '/admin/email/readtemp',
  AddEmailTemplate: '/admin/email/addtemp',
  UpdateEmailTemplate: '/admin/email/updatetemp',
  DelEmailTemplate: '/admin/email/deltemp',
  EmailSelectSet: '/admin/email/selectset',
  saveEmailSelectSet: '/admin/email/saveSelectSet',
  EmailLog: '/admin/email/log',

  // sms
  SmsReplaceVars: '/admin/sms/replaceVars',
  SmsTemplate: '/admin/sms/template',
  SmsAddTemplate: '/admin/sms/addTemplate',
  SmsEditTemplate: 'admin/sms/editTemplate',
  SmsDelTemplate: '/admin/sms/delTemplate',
  SmsSelectSet: '/admin/sms/selectSet',
  SaveSmsSelectSet: '/admin/sms/saveSelectSet',
  SmsLog: '/admin/sms/log',

  // Announcement
  AnnounceType: '/admin/announcement/type',
  AnnounceList: '/admin/announcement/list',
  AnnounceRead: '/admin/announcement/read',
  AnnounceAdd: '/admin/announcement/add',
  AnnounceEdit: '/admin/announcement/edit',
  AnnounceDelete: '/admin/announcement/delete',

  // Course
  CourseCategory: '/admin/course/category',
  CourseCategoryInfo: '/admin/course/categoryInfo',
  CourseCategoryCreate: '/admin/course/categoryCreate',
  CourseCategorySave: '/admin/course/categorySave',
  CourseCategoryDelete: '/admin/course/categoryDelete',
  CourseList: '/admin/course/list',
  CourseInfo: '/admin/course/Info',
  CourseCreate: '/admin/course/create',
  CourseSave: '/admin/course/save',
  CourseDelete: '/admin/course/delete',

  // User
  UserList: '/admin/user/list',
  UserInfo: '/admin/user/info',
  UserSearch: '/admin/user/search',
  UserCreate: '/admin/user/create',
  UserSave: '/admin/user/save',
  UserDelete: '/admin/user/delete',
  UserAdditional: '/admin/user/additional',
  UserStudentCreate: '/admin/user/studentCreate',
  UserStudentSave: '/admin/user/studentSave',
  UserStudentDelete: '/admin/user/studentDelete',

  // Teacher
  TeacherList: '/admin/teacher/list',
  TeacherInfo: '/admin/teacher/info',
  TeacherSearch: '/admin/teacher/search',
  TeacherCreate: '/admin/teacher/create',
  TeacherSave: '/admin/teacher/save',
  TeacherDelete: '/admin/teacher/delete',
  TeacherAdditional: '/admin/teacher/additional',

  // TeacherInfo
  TeacherInfoList: '/admin/teacher_info/list',
  TeacherInfoDetail: '/admin/teacher_info/detail',
  TeacherInfoApproved: '/admin/teacher_info/approved',
  TeacherInfoAuditFailed: '/admin/teacher_info/auditFailed',

  // ServicePack
  ServicePackList: '/admin/service_pack/list',
  ServicePackInfo: '/admin/service_pack/info',
  PackUsed: '/admin/service_pack/packUsed',
  ServicePackAdditional: '/admin/service_pack/additional',
  ServicePackCreate: '/admin/service_pack/create',
  ServicePackSave: '/admin/service_pack/save',
  ServicePackDelete: '/admin/service_pack/delete',

  // Order
  OrderList: '/admin/order/list',
  OrderInfo: '/admin/order/info',
  OrderAdditional: '/admin/order/additional',
  OrderCreate: '/admin/order/create',
  OrderCancel: '/admin/order/cancel',
  OrderToChangeAmount: '/admin/order/toChangeAmount',
  OrderToChangePaid: '/admin/order/toChangePaid',
  OrderToChangeActive: '/admin/order/toAcitve',
  OrderToChangeFraud: '/admin/order/toFraud',

  // News
  NewsList: '/admin/news/list',
  NewsInfo: '/admin/news/info',
  NewsAdditional: '/admin/news/additional',
  NewsCreate: '/admin/news/create',
  NewsSave: '/admin/news/save',
  NewsDelete: '/admin/news/delete',

  // system log
  SystemActiveLog: '/admin/system/activeLog',
  SystemAdminLog: '/admin/system/adminLog',

  // 论坛
  ForumList: '/admin/forum/list',
  ForumInfo: '/admin/forum/info',
  ForumCreate: '/admin/forum/create',
  ForumSave: '/admin/forum/save',
  ForumDelete: '/admin/forum/delete',

  // 首页轮播
  RotationList: '/admin/rotation/list',
  RotationInfo: '/admin/rotation/info',
  RotationCreate: '/admin/rotation/create',
  RotationUpdate: '/admin/rotation/update',
  RotationDelete: '/admin/rotation/delete'
}
export default api
