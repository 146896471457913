// eslint-disable-next-line
import { UserLayout, BasicLayout, RouteView, BlankLayout, PageView } from '@/layouts'
import { bxAnaalyse } from '@/core/icons'

export const asyncRouterMap = [
  {
    path: '/',
    name: 'index',
    component: BasicLayout,
    meta: { title: '首页' },
    redirect: '/dashboard/analysis',
    children: [
      // download
      {
        path: 'download',
        name: 'download',
        redirect: '/download/file',
        component: RouteView,
        hidden: true,
        meta: { title: '下载页' },
        children: [
          {
            path: '/download/file',
            name: 'DownloadFile',
            component: () => import('@/views/download/file'),
            meta: { title: '下载文件' }
          }
        ]
      },
      // dashboard
      {
        path: 'dashboard',
        name: 'dashboard',
        redirect: '/dashboard/analysis',
        component: PageView,
        meta: { title: '仪表盘', keepAlive: true, icon: bxAnaalyse, permission: ['dashboard', 'admin/rule/getlist'] },
        children: [
          {
            path: 'analysis/:pageNo([1-9]\\d*)?',
            name: 'Analysis',
            component: () => import('@/views/dashboard/Analysis'),
            meta: { title: '分析页', keepAlive: false, permission: ['admin/rule/getlist'] }
          }
          // {
          //   path: 'workplace',
          //   name: 'Workplace',
          //   component: () => import('@/views/dashboard/Workplace'),
          //   meta: { title: '工作台', keepAlive: true, permission: [ 'admin/rule/getlist' ] }
          // }
          // 外部链接
          // {
          //   path: 'https://www.baidu.com/',
          //   name: 'Monitor',
          //   meta: { title: '监控页（外部）', target: '_blank' }
          // },
          // {
          //   path: 'test-work',
          //   name: 'TestWork',
          //   component: () => import('@/views/dashboard/TestWork'),
          //   meta: { title: '测试功能', keepAlive: true, permission: [ 'dashboard', 'admin/rule/getlist' ] }
          // }
        ]
      },
      {
        path: '/clients',
        name: 'Clients',
        component: PageView,
        meta: { title: '用户管理', icon: 'user', permission: ['admin/rule/getlist'] },
        redirect: '/clients/list',
        children: [
          {
            path: '/clients/list',
            name: 'ClientsList',
            component: () => import('@/views/clients/List'),
            meta: { title: '用户列表', keepAlive: false }
          },
          {
            path: '/clients/add',
            name: 'ClientsAdd',
            component: () => import('@/views/clients/Add'),
            meta: { title: '添加用户', keepAlive: false }
          },
          {
            path: '/clients/detail',
            name: 'ClientsDetail',
            component: () => import('@/views/clients/Detail'),
            hidden: true,
            meta: { title: '用户详情', keepAlive: false }
          }
        ]
      },
      {
        path: '/teacher',
        name: 'Teacher',
        component: PageView,
        meta: { title: '教员管理', icon: 'smile' },
        redirect: '/teacher/list',
        children: [
          {
            path: '/teacher/list',
            name: 'TeacherList',
            component: () => import('@/views/teacher/List'),
            meta: { title: '教员列表', keepAlive: false }
          },
          {
            path: '/teacher/detail',
            name: 'TeacherDetail',
            component: () => import('@/views/teacher/Detail'),
            hidden: true,
            meta: { title: '教员详情', keepAlive: false }
          },
          {
            path: '/teacher/add',
            name: 'TeacherAdd',
            component: () => import('@/views/teacher/Add'),
            meta: { title: '添加教员', keepAlive: false }
          },
          {
            path: '/teacher/excellet',
            name: 'TeacherExcellet',
            component: () => import('@/views/other/PermissionList'),
            meta: { title: '优秀教员', keepAlive: true }
          },
          {
            path: '/teacher/info-list',
            name: 'TeacherInfoList',
            component: () => import('@/views/teacher/InfoList'),
            meta: { title: '审核申请', keepAlive: true }
          }
        ]
      },
      {
        path: '/order',
        name: 'Order',
        component: PageView,
        meta: { title: '订单管理', icon: 'file-text' },
        redirect: '/order/list',
        children: [
          {
            path: '/order/list',
            name: 'OrderList',
            component: () => import('@/views/order/List'),
            meta: { title: '订单列表', keepAlive: false }
          },
          {
            path: '/order/add',
            name: 'OrderAdd',
            component: () => import('@/views/order/Add'),
            meta: { title: '添加订单', keepAlive: true }
          },
          {
            path: '/order/edit',
            name: 'OrderEdit',
            component: () => import('@/views/order/Edit'),
            hidden: true,
            meta: { title: '查看订单', keepAlive: true }
          }
        ]
      },
      {
        path: '/invoice',
        name: 'Invoice',
        component: PageView,
        meta: { title: '账单管理', icon: 'money-collect' },
        redirect: '/invoice/list',
        children: [
          {
            path: '/invoice/list',
            name: 'InvoiceList',
            component: () => import('@/views/other/PermissionList'),
            meta: { title: '账单列表' }
          },
          {
            path: '/invoice/bill-flow',
            name: 'BillFlow',
            component: () => import('@/views/other/PermissionList'),
            meta: { title: '流水管理' }
          }
        ]
      },
      {
        path: '/system',
        name: 'System',
        component: PageView,
        meta: { title: '系统状态', icon: 'safety-certificate' },
        redirect: '/system/log',
        children: [
          {
            path: '/system/log',
            name: 'SystemLog',
            component: () => import('@/views/system/LogList'),
            meta: { title: '系统日志' }
          },
          {
            path: '/system/admin-log',
            name: 'SystemAdminLog',
            component: () => import('@/views/system/AdminLog'),
            meta: { title: '管理员日志' }
          }
        ]
      },
      {
        path: '/information',
        name: 'Information',
        component: PageView,
        meta: { title: '信息发布', icon: 'notification' },
        redirect: '/information/notice',
        children: [
          {
            path: '/information/notice-list',
            name: 'InformationNotic',
            component: () => import('@/views/information/NoticeList'),
            meta: { title: '公告列表' }
          },
          {
            path: '/information/notice-edit',
            name: 'InformationNoticEdit',
            component: () => import('@/views/information/NoticeEdit'),
            hidden: true,
            meta: { title: '编辑公告' }
          },
          {
            path: '/information/news',
            name: 'InformationNews',
            component: () => import('@/views/information/NewsList'),
            meta: { title: '新闻列表' }
          },
          {
            path: '/information/news-edit',
            name: 'InformationNewsEdit',
            component: () => import('@/views/information/NewsEdit'),
            hidden: true,
            meta: { title: '编辑新闻' }
          },
          {
            path: '/information/forum',
            name: 'InformationForum',
            component: () => import('@/views/information/ForumList'),
            meta: { title: '帖子列表' }
          },
          {
            path: '/information/forum-edit',
            name: 'InformationForumEdit',
            hidden: true,
            component: () => import('@/views/information/ForumEdit'),
            meta: { title: '编辑帖子' }
          },
          {
            path: '/information/forum-info',
            name: 'InformationForumInfo',
            hidden: true,
            component: () => import('@/views/information/ForumInfo'),
            meta: { title: '帖子详情' }
          },
          {
            path: '/information/help-cates',
            name: 'HelpCates',
            component: () => import('@/views/information/HelpCates'),
            meta: { title: '帮助菜单' }
          },
          {
            path: '/information/help-cates-Add',
            name: 'HelpCatesAdd',
            hidden: true,
            component: () => import('@/views/information/HelpCatesAdd'),
            props: true, // 使用props传递参数
            meta: { title: '编辑帮助' }
          },
          {
            path: '/information/helps',
            name: 'InformationHelps',
            component: () => import('@/views/information/Helps'),
            meta: { title: '帮助文章' }
          },
          {
            path: '/information/help-edit',
            name: 'HelpEdit',
            hidden: true,
            component: () => import('@/views/information/HelpEdit'),
            meta: { title: '编辑帮助' }
          },
          {
            path: '/information/article',
            name: 'InformationArticle',
            component: () => import('@/views/information/Article'),
            meta: { title: '其他文章' }
          },
          {
            path: '/information/article-edit',
            name: 'ArticleEdit',
            hidden: true,
            component: () => import('@/views/information/ArticleEdit'),
            meta: { title: '编辑文章' }
          }
        ]
      },
      {
        path: '/work-order',
        name: 'WorkOrder',
        component: PageView,
        meta: { title: '工单管理', icon: 'file-done' },
        redirect: '/work-order/list',
        children: [
          {
            path: '/work-order/list',
            name: 'WorkOrderList',
            component: () => import('@/views/work-order/List'),
            meta: { title: '工单列表' }
          },
          {
            path: '/work-order/details/:id',
            name: 'WorkOrderDetails',
            hidden: true,
            component: () => import('@/views/work-order/Details'),
            props: true,
            meta: { title: '工单详情' }
          }
        ]
      },
      // account
      {
        path: '/account',
        component: PageView,
        redirect: '/account/settings',
        name: 'account',
        meta: { title: '个人页', icon: 'user', keepAlive: true, permission: ['user', 'admin/rule/getlist'] },
        children: [
          {
            path: '/account/settings',
            name: 'settings',
            component: () => import('@/views/account/settings/Index'),
            meta: { title: '我的账户', hideHeader: true }
          }
        ]
      },
      {
        path: '/config',
        name: 'Config',
        component: PageView,
        meta: { title: '系统设置', icon: 'tool', permission: ['admin/rule/getlist'] },
        redirect: '/config/web-config',
        children: [
          {
            path: '/config/web',
            name: 'ConfigWeb',
            component: RouteView,
            meta: { title: '网站配置', icon: 'setting' },
            redirect: '/config/web/web-config',
            children: [
              {
                path: '/config/web/web-config',
                name: 'WebConfig',
                component: () => import('@/views/config/web/Setting'),
                meta: { title: '网站设置', keepAlive: true }
              },
              {
                path: '/config/web/rotation-list',
                name: 'RotationList',
                component: () => import('@/views/config/web/RotationList'),
                meta: { title: '首页轮播', keepAlive: true }
              },
              {
                path: '/config/web/rotation-edit',
                name: 'RotationEdit',
                hidden: true,
                component: () => import('@/views/config/web/RotationEdit'),
                meta: { title: '首页轮播', keepAlive: false }
              },
              {
                path: '/config/web/node',
                name: 'AuthNode',
                component: () => import('@/views/config/web/AuthNode'),
                meta: { title: '节点设置', keepAlive: true, permission: ['admin/rule/getlist'] }
              },
              {
                path: '/config/web/add-node',
                name: 'AddNode',
                component: () => import('@/views/config/web/AddNode'),
                hidden: true,
                meta: { title: '添加节点', permission: ['admin/rule/save'] }
              }
            ]
          },
          {
            path: '/config/admin',
            name: 'Admin',
            component: RouteView,
            meta: { title: '管理员管理', icon: 'team', permission: ['admin/admin/getlist', 'admin/admingroup/getlist'] },
            redirect: '/config/admin/list',
            children: [
              {
                path: '/config/admin/list',
                name: 'AdminList',
                meta: { title: '管理员', permission: ['admin/admin/getlist'] },
                component: () => import('@/views/config/admin/List')
              },
              {
                path: '/config/admin/edit',
                name: 'AdminEdit',
                component: () => import('@/views/config/admin/Edit'),
                hidden: true,
                meta: { title: '编辑管理员' }
              },
              {
                path: '/config/admin/group',
                name: 'AdminGroup',
                component: () => import('@/views/config/admin/GroupList'),
                meta: { title: '管理组', keepAlive: true, permission: ['admin/admingroup/getlist'] }
              },
              {
                path: '/config/admin/group-edit',
                name: 'AdminGroup',
                component: () => import('@/views/config/admin/GroupEdit'),
                hidden: true,
                meta: { title: '编辑管理组' }
              }
            ]
          },
          {
            path: '/config/sms',
            name: 'Sms',
            component: RouteView,
            meta: { title: '短信管理', icon: 'message' },
            children: [
              {
                path: '/config/sms/template-list',
                name: 'SmsTemplateList',
                component: () => import('@/views/config/sms/List'),
                meta: { title: '模板列表', keepAlive: true }
              },
              {
                path: '/config/sms/settings',
                name: 'SmsSettings',
                component: () => import('@/views/config/sms/Settings'),
                meta: { title: '发送设置' }
              },
              {
                path: '/config/sms/template-edit',
                name: 'SmsTemplateEdit',
                component: () => import('@/views/config/sms/Edit'),
                hidden: true,
                meta: { title: '编辑模板' }
              },
              {
                path: '/config/sms/log',
                name: 'SmsLog',
                component: () => import('@/views/config/sms/Log'),
                meta: { title: '发送日志', keepAlive: true }
              }
            ]
          },
          {
            path: '/config/email',
            name: 'Email',
            component: RouteView,
            meta: { title: '邮件管理', icon: 'mail' },
            children: [
              {
                path: '/config/email/template-list',
                name: 'EmailTemplateList',
                component: () => import('@/views/config/email/List'),
                meta: { title: '模板列表', keepAlive: true }
              },
              {
                path: '/config/email/settings',
                name: 'EmailSettings',
                component: () => import('@/views/config/email/Settings'),
                meta: { title: '发送设置' }
              },
              {
                path: '/config/email/template-edit',
                name: 'EmailTemplateEdit',
                component: () => import('@/views/config/email/Edit'),
                hidden: true,
                meta: { title: '编辑模板', keepAlive: false }
              },
              {
                path: '/config/email/log',
                name: 'EmailLog',
                component: () => import('@/views/config/email/Log'),
                meta: { title: '发送日志', keepAlive: true }
              }
            ]
          },
          {
            path: '/config/realname',
            name: 'RealName',
            component: RouteView,
            meta: { title: '实名管理', icon: 'contacts' },
            children: [
              {
                path: '/config/realname/list',
                name: 'RealNameList',
                component: () => import('@/views/config/realname/List'),
                meta: { title: '实名列表', keepAlive: true }
              },
              {
                path: '/con fig/realname/log',
                name: 'RealNameLog',
                component: () => import('@/views/config/realname/Log'),
                meta: { title: '实名日志', keepAlive: true }
              }
            ]
          },
          {
            path: '/config/course',
            name: 'Course',
            component: RouteView,
            meta: { title: '课程管理', icon: 'read' },
            children: [
              {
                path: '/config/course/category',
                name: 'CourseCategory',
                component: () => import('@/views/config/course/Category'),
                meta: { title: '课程类别', keepAlive: false }
              },
              {
                path: '/config/course/category-edit',
                name: 'CourseCategoryEdit',
                component: () => import('@/views/config/course/CategoryEdit'),
                hidden: true,
                meta: { title: '编辑课程类别', keepAlive: false }
              },
              {
                path: '/config/course/list',
                name: 'CourseList',
                component: () => import('@/views/config/course/List'),
                meta: { title: '课程列表', keepAlive: false }
              },
              {
                path: '/config/course/list-edit',
                name: 'CourseListEdit',
                component: () => import('@/views/config/course/ListEdit'),
                hidden: true,
                meta: { title: '编辑课程', keepAlive: false }
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '*', redirect: '/404', hidden: true
  }
]

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/user',
    component: UserLayout,
    redirect: '/user/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Login')
      },
      {
        path: 'register',
        name: 'register',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Register')
      },
      {
        path: 'register-result',
        name: 'registerResult',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/RegisterResult')
      },
      {
        path: 'recover',
        name: 'recover',
        component: undefined
      }
    ]
  },

  {
    path: '/test',
    component: BlankLayout,
    redirect: '/test/home',
    children: [
      {
        path: 'home',
        name: 'TestHome',
        component: () => import('@/views/Home')
      }
    ]
  },
  {
    path: '/404',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/404')
  }
]
